import React, {useRef} from 'react';

import {CommunityPerks} from 'src/components/experience/CommunityPerks';
import {Hero} from 'src/components/experience/Hero';
import {ImageCarousel} from 'src/components/experience/ImageCarousel';
import {InvestSection} from 'src/components/experience/InvestSection';
import {MembershipCriteria} from 'src/components/experience/MembershipCriteria';
import {MembershipOptions} from 'src/components/experience/MembershipOptions';
import {Testimonials} from 'src/components/experience/Testimonials';
import {VideoSection} from 'src/components/experience/VideoSection';
import {Box, SEOWithQuery} from 'src/components/shared';
import {seo} from 'src/utils/copy';

const ExperiencePage: React.FC = ({...props}) => {
  const containerMaxWidth = '80.1875rem';

  return (
    <Box backgroundColor="darkGreen">
      <Hero containerMaxWidth={containerMaxWidth} />
      <MembershipOptions containerMaxWidth={containerMaxWidth} />
      <CommunityPerks containerMaxWidth={containerMaxWidth} />
      <ImageCarousel containerMaxWidth={containerMaxWidth} />
      <InvestSection containerMaxWidth={containerMaxWidth} />
      <VideoSection containerMaxWidth={containerMaxWidth} />
      <Testimonials containerMaxWidth={containerMaxWidth} />
      <MembershipCriteria containerMaxWidth={containerMaxWidth} />

      <SEOWithQuery title={seo.explore.title} description={seo.explore.description} />
    </Box>
  );
};

export default ExperiencePage;
